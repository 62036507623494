import { Icon } from "@iconify/react";
import { Link } from "gatsby";
import React from "react";
import { Stack } from "react-bootstrap";
import styled from "styled-components";
import ButtonLink from "../../../components/atoms/ButtonLink";

const NavBar = ({ link, title }: { link: any; title: string }) => {
  const ButtonLink = styled(Link)``;

  return (
    <Stack
      dir="rtl"
      direction="horizontal"
      style={{ transform: "translateX(-12px)" }}
      className="align-items-center mt-5 px-3"
    >
      <div className="mx-auto">
        <p className="hero-subheading">{title}</p>
      </div>

      <ButtonLink to={link}>
        <Icon
          cursor="pointer"
          fontSize={"3.5rem"}
          color="var(--text-gray)"
          icon="ic:round-arrow-back-ios-new"
        />
      </ButtonLink>
      <div></div>
    </Stack>
  );
};

export default NavBar;
