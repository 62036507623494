import React from "react";
import HeadingComponent from "./components/heading";
import bg from "../../images/packages/bg.png";
import Header from "../../components/organisms/Header";
import { Media } from "../../components/atoms/Media";
import ToolBarSection from "../../components/organisms/ToolBarSection";
import MobileNavigator from "../../components/atoms/MobileNavigator";
import HeadingUnderLine from "../../components/atoms/HeadingUnderLine";
import FooterMobile from "../../components/MobileViewComponents/Footer/FooterMobile";
import Footer from "../../components/organisms/Footer";
import BusinessPackagesCards from "./components/business-packages-cards";
import NavBar from "./components/Navbar";

const Business = () => {
  return (
    <>
      <div className="hero-body">
        <Media greaterThan="sm">
          <ToolBarSection business={false} />
          <Header />
        </Media>
        <div className="">
          <Media at="sm">
            <NavBar link="/" title="من نحن" />
          </Media>

          <Media greaterThan="sm">
            <HeadingUnderLine title=" " />
          </Media>
        </div>

        <HeadingComponent
          heading="منصة شورى؟"
          subheading="من نحن"
          description="منصة إلكترونية تقدم خدمات واستشارات قانونية للأفراد والشركات وأصحاب الأعمال، من خلال ربطهم بمحامين متخصصين ومرخصين من وزارة العدل السعودية. تعمل شورى على مدار الساعة في جميع مناطق المملكة، مع ضمان المهنية والجودة والسرية. كما تقدم باقات خدمات مخصصة لتلبية الاحتياجات القانونية للشركات وأصحاب الأعمال بتكلفة مميزة."
          imgSrc={bg}
          hideOnMobile={true}
        />

        <div className="cards-wrapper">
          <HeadingComponent heading=" باقـاتنا" subheading=" شورى للاعمال " />
          <BusinessPackagesCards />
        </div>
        <Media at="sm">
          <FooterMobile />
        </Media>

        <Media greaterThan="sm">
          <Footer />
        </Media>
      </div>
    </>
  );
};

export default Business;
